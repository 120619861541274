<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content email-app-sidebar">
        <div
          :class="{'email-app-menu': true, 'sws-not-button-create': false }"
        >
          <div
            class="form-group-compose text-center compose-btn"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              block
              :disabled="$store.getters['crm_leads/loadingListview']"
              @click="createNew($event);$emit('close-left-sidebar');"
            >
              {{ $t('Novo cliente') }}
            </b-button>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { sizeIconsAction } from '@core-custom/mixins/geral'

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
  },
  mixins: [sizeIconsAction],
  props: {
    createNew: {
      type: Function,
      required: true,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.email-application .content-area-wrapper .sidebar .email-app-sidebar .email-app-menu.sws-not-button-create .sidebar-menu-list {
  height: calc(100%);
}
</style>
