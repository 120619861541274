import api from '@core-custom/api/api'
import apiConfig from '@core-custom/api/apiConfig'
import i18n from '@/libs/i18n'
import defaultState from './defaultState'

export default {
  namespaced: true,
  state: defaultState,
  getters: {
    listview_txt_default: state => ({
      txt: state.listview_txt_default,
      txt_not_find: state.listiview_txt_not_find,
      txt_search: state.listiview_txt_search,
      txt_loading: state.listview_txt_loading,
    }),
    listviewInit: state => state.listviewInit.value,
    listviewData: state => state.listviewData.value,
    listviewOrderBy: state => state.listviewOrderBy.value,
    totalRows: state => state.totalRows.value,
    perPage: state => state.perPage.value,
    loadingListview: state => state.loadingListview.value,
    currentPage: state => state.currentPage.value,
    searchTxt: state => state.searchTxt.value,
    fieldsOrder: state => state.aFieldsOrder,
    positionListview: state => state.positionListview,
    showPager: state => state.totalRows.value > state.perPage.value,
    filterSelected: state => ({
      totalFields: state.filterSelectedTotalFields,
    }),
  },
  mutations: {
    resetState(state) {
      Object.assign(state, defaultState)
    },
    setLoadingListview(state, payload) {
      state.loadingListview.value = payload
    },
    setListviewData(state, payload) {
      state.listviewData.value = payload
    },
    setTotalRows(state, payload) {
      state.totalRows.value = payload
    },
    setSearchTxt(state, payload) {
      state.searchTxt.value = payload
    },
    setCurrentPage(state, payload) {
      state.currentPage.value = payload
    },
    setListviewOrderBy(state, payload) {
      state.listviewOrderBy.value = {
        sort: payload.sort,
        column: payload.column,
      }
    },
    setListviewInit(state, payload) {
      state.listviewInit.value = payload
    },
    resetAllFilterSelected(state) {
      state.searchTxt.value = ''
    },
    savePositionListview(state, payload) {
      state.positionListview = payload || {}
    },
    addFilterSelectedTotalFields(state) {
      state.filterSelectedTotalFields.value += 1
    },
    resetFilterSelectedTotalFields(state) {
      state.filterSelectedTotalFields.value = 0
    },
  },
  actions: {
    async fetchListView({
      state, commit, dispatch,
    }, payload) {
      const listviewFilterParams = new FormData()
      listviewFilterParams.append('modeListview', true)

      commit('resetFilterSelectedTotalFields')

      if (state.searchTxt.value !== '') {
        listviewFilterParams.append('search', state.searchTxt.value)
        commit('addFilterSelectedTotalFields')
      }

      if (state.listviewOrderBy.value.column !== undefined) {
        listviewFilterParams.append('orderByColumn', state.listviewOrderBy.value.column)
      }

      if (state.listviewOrderBy.value.sort !== undefined) {
        listviewFilterParams.append('orderBySort', state.listviewOrderBy.value.sort)
      }

      // Por defeito, caso nao seja selecionada a pagina, faz sempre reset para a pagina "1"
      if ((payload !== null) && (payload !== undefined) && ('skipCurrentPage' in payload) && (payload.skipCurrentPage === true)) {
        // Fix para nao alterar a pagina que foi selecionada pelo utilizador
      } else if (state.currentPage.value !== 1) {
        commit('setCurrentPage', 1)
      }

      listviewFilterParams.append('page', ((state.currentPage.value - 1) * state.perPage.value))
      listviewFilterParams.append('limit', state.perPage.value)

      if (!!payload && (payload.loadingDisable !== undefined) && (payload.loadingDisable === true)) {
        commit('setListviewInit', 1)
      } else {
        commit('setListviewData', [])
        commit('setTotalRows', 0)
        commit('setLoadingListview', true)
        commit('setListviewInit', 1)
      }

      await dispatch('request/cancelTokensPendingByUrl', 'index.php?dispatch=bestquimica_api.crm_getAllClients', { root: true })

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}index.php?dispatch=bestquimica_api.crm_getAllClients`, listviewFilterParams)
          .then(response => {
            if (response.data?.data !== undefined) {
              commit('setListviewData', response.data.data)
              commit('setTotalRows', response.data.total)
              commit('setLoadingListview', false)
              commit('setListviewInit', 0)
              resolve(true)
            } else {
              commit('setListviewData', [])
              commit('setTotalRows', 0)
              commit('setLoadingListview', false)
              commit('setListviewInit', 0)
              reject(new Error('Problema ao carregar a listagem'))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              commit('setListviewData', [])
              commit('setTotalRows', 0)
              commit('setLoadingListview', false)
              commit('setListviewInit', 0)
              reject(new Error(i18n.t('Problema ao carregar a listagem')))
            }
          })
      })
    },

    listviewFilterOrderBy({ state, commit, dispatch }, field) {
      if ((!!state.listviewOrderBy.value.column) && (state.listviewOrderBy.value.column === field)) {
        if (state.listviewOrderBy.value.sort === 'asc') {
          commit('setListviewOrderBy', {
            sort: 'desc',
            column: field,
          })
        } else {
          commit('setListviewOrderBy', {
            sort: 'asc',
            column: field,
          })
        }
      } else {
        commit('setListviewOrderBy', {
          sort: 'asc',
          column: field,
        })
      }

      commit('setCurrentPage', 1)

      return dispatch('fetchListView')
    },

    setSearchTxt({ commit, dispatch }, value) {
      commit('setSearchTxt', value)
      commit('setCurrentPage', 1)
      return dispatch('fetchListView', { loadingDisable: true })
    },

    setCurrentPage({ commit, dispatch }, value) {
      commit('setCurrentPage', value)
      return dispatch('fetchListView', { skipCurrentPage: true })
    },

    pullToRefreshListview({ commit, dispatch }) {
      commit('setCurrentPage', 1)
      return dispatch('fetchListView', { loadingDisable: true })
    },

    resetListviewFilterGeral({ commit, dispatch }) {
      commit('resetAllFilterSelected')
      return dispatch('fetchListView')
    },

  },
}
