import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'

const defaultState = () => ({
  listviewInit: ref(1),
  listviewData: ref([]),
  positionListview: {},
  loadingListview: ref(true),
  perPage: ref(25),
  totalRows: ref(0),
  currentPage: ref(1),
  perPageOptions: ref([10, 25, 50, 100]),
  searchTxt: ref(''),
  listviewOrderBy: ref({
    column: 'lastChange',
    sort: 'desc',
  }),
  listview_txt_default: i18n.t('Nenhum resultado disponível'),
  listiview_txt_not_find: i18n.t('Não encontrou nenhum resultado'),
  listiview_txt_search: i18n.t('Nome, telemóvel, email, abreviatura ou número cliente'),
  listview_txt_loading: i18n.t('A carregar registos ...'),
  aFieldsOrder: [
    {
      name: 'name',
      txt: i18n.t('Ordenar por nome'),
    },
    {
      name: 'nameAbbr',
      txt: i18n.t('Ordenar por abreviatura'),
    },
    {
      name: 'numberClient',
      txt: i18n.t('Ordenar por número cliente'),
    },
    {
      name: 'nif',
      txt: i18n.t('Ordenar por contribuinte'),
    },
  ],
  filterSelectedTotalFields: ref(0),
})

export default defaultState
