<template>
  <ul
    class="email-media-list"
  >
    <b-media
      v-for="(row) in listviewData"
      :key="row.id"
      tag="li"
      no-body
    >
      <b-media-aside class="media-left mr-100">
        <b-avatar
          class="avatar"
          size="40"
          variant="primary"
        />
      </b-media-aside>
      <b-media-body>
        <div class="mail-details">
          <div class="mail-items">
            <h5
              v-if="renderName(row) !== ''"
              class="mb-25"
            >
              {{ renderName(row) }}
            </h5>
            <a
              v-if="renderEmails(row) !== ''"
              :class="[{'txt-grey-link': true }]"
            >
              <feather-icon
                icon="MailIcon"
                class="mr-50"
              />
              {{ renderEmails(row) }}
            </a>
            <a
              v-if="renderPhones(row) !== ''"
              :class="[{'d-none d-sm-inline': true, 'px-2': ((renderEmails(row) !== '') ? true : false), 'txt-grey-link' : true}]"
            >
              <feather-icon
                icon="PhoneCallIcon"
                class="mr-50"
              />
              {{ renderPhones(row) }}
            </a>
            <a
              v-if="row.phone"
              :href="`tel:${row.phone}`"
              :class="[{'d-inline d-sm-none': true, 'txt-grey-link': true}]"
            >
              <feather-icon
                icon="PhoneCallIcon"
              />
              {{ row.phone }}
            </a>
          </div>
        </div>

        <div
          v-if="renderNameAbbr(row)"
          class="mail-message"
        >
          <p class="mb-0">
            {{ $t('Abreviatura') }}: {{ renderNameAbbr(row) }}
          </p>
        </div>

        <div
          v-if="renderNumberClient(row)"
          class="mail-message"
        >
          <p class="mb-0">
            {{ $t('Número cliente') }}: {{ renderNumberClient(row) }}
          </p>
        </div>

        <div
          v-if="renderNif(row)"
          class="mail-message"
        >
          <p class="mb-0">
            {{ $t('Contribuinte') }}: {{ renderNif(row) }}
          </p>
        </div>
      </b-media-body>
    </b-media>
  </ul>
</template>

<script>
import {
  BMedia, BMediaAside, BMediaBody, BAvatar,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
  },
  computed: {
    ...mapGetters('crm_clients', ['listviewData']),
  },
  methods: {
    renderName(row) {
      return row?.name || ''
    },
    renderEmails(row) {
      const sEmails = row?.emails || ''
      let aEmails = []

      if (sEmails !== '') {
        aEmails = sEmails.split(';')
        aEmails = aEmails.filter(el => el)
      }

      return aEmails.join(', ')
    },
    renderPhones(row) {
      const sPhones = row?.phones || ''
      let aPhones = []

      if (sPhones !== '') {
        aPhones = sPhones.split(';')
        aPhones = aPhones.filter(el => el)
      }

      return aPhones.join(', ')
    },
    renderNumberClient(row) {
      return row?.numberClient || ''
    },
    renderNif(row) {
      return row?.nif || ''
    },
    renderNameAbbr(row) {
      return row?.nameAbbr || ''
    },
  },
}
</script>

<style lang="scss" scoped>
.txt-grey-link {
  color: $text-muted;
}
</style>
