<template>
  <div style="height: inherit">

    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar = false"
    />

    <div class="email-app-list position-relative space-bottom">

      <!-- Barra pesquisa / ordenação -->
      <div
        class="app-fixed-search d-flex align-items-center"
      >

        <div class="sidebar-toggle d-block d-lg-none ml-1">
          <feather-icon
            icon="MenuIcon"
            :size="sizeIconsAction"
            class="cursor-pointer"
            @click="mqShallShowLeftSidebar = true"
          />
        </div>

        <div class="d-flex align-content-center justify-content-between w-100">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="searchTxt"
              value=""
              :placeholder="listview_txt_default.txt_search"
            />
          </b-input-group>
        </div>

        <div
          v-if="fieldsOrder.length > 0"
          class="dropdown"
        >
          <b-dropdown
            variant="link"
            no-caret
            toggle-class="p-0 mr-1"
            right
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                :size="sizeIconsAction"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-for="oField in fieldsOrder"
              :key="`field-order-${oField.name}`"
              @click="listviewFilterOrderBy(oField.name)"
            >
              <feather-icon
                :icon="listviewIconOrderBy(oField.name)"
                :class="listviewIconOrderBy(oField.name, true)"
              />
              {{ oField.txt }}
            </b-dropdown-item>
          </b-dropdown>
        </div>

      </div>

      <!-- Barra de ações dos registos da listagem -->
      <div
        v-if="listviewData.length > 0"
        class="app-action"
      >
        <div class="action-left align-items-center d-flex font-weight-bolder">
          {{ $t('Total') }}: {{ totalRows }}
        </div>
      </div>

      <!-- Listagem -->
      <div
        class="email-user-list scroll-area"
      >
        <vue-pull-refresh
          ref="listviewScroll"
          :on-refresh="listViewRefresh"
          :config="{
            errorLabel: $t('Problema ao atualizar'),
            startLabel: $t('Baixar para atualizar'),
            readyLabel: $t('Solte para atualizar'),
            loadingLabel: $t('A atualizar...'),
            pullDownHeight: 60,
          }"
          @scroll.native="listViewSavePositionScroll"
        >
          <listview-list-data
            v-if="listviewData.length > 0"
            ref="list"
          />
          <div
            v-if="listviewData.length === 0"
            class="no-results"
            :class="{'show': !listviewData.length}"
          >
            <h5 v-if="(filterSelected.totalFields.value === 0) && (listviewInit === 0)">
              {{ listview_txt_default.txt }}
            </h5>
            <h5 v-if="(filterSelected.totalFields.value > 0) && (listviewInit === 0)">
              {{ listview_txt_default.txt_not_find }}
            </h5>
            <h5 v-if="listviewInit === 1">
              {{ listview_txt_default.txt_loading }}
            </h5>
          </div>
        </vue-pull-refresh>
      </div>

      <b-container
        v-if="listviewData.length > 0"
        fluid
      >
        <b-row>
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-center"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              class="mb-0 pagination-white pt-0_2 pb-0_2"
              :size="sizePaginationListviewResponsive"
            />
          </b-col>
        </b-row>
      </b-container>

      <b-overlay
        :show="$store.getters['crm_clients/loadingListview']"
        no-wrap
      />

    </div>

    <portal
      to="content-renderer-sidebar-left"
    >
      <listview-left-sidebar
        ref="leftSidebar"
        :class="{'show': mqShallShowLeftSidebar}"
        :create-new="createNew"
        :mq-shall-show-left-sidebar="mqShallShowLeftSidebar"
        @close-left-sidebar="mqShallShowLeftSidebar = false"
      />
    </portal>

  </div>

</template>

<script>
import store from '@/store'
import '@/libs/sweet-alerts'
import { onUnmounted, computed } from '@vue/composition-api'
import {
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  VBTooltip,
  BDropdown,
  BDropdownItem,
  BRow,
  BCol,
  BContainer,
  BPagination,
  BOverlay,
} from 'bootstrap-vue'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import crmClientsListModule from '@store-modules/crm/clients/listview'
import { mapGetters } from 'vuex'
import { sizeIconsAction, showMsgRequest } from '@core-custom/mixins/geral'
import { useRouter } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'
import VuePullRefresh from 'vue-pull-refresh'
import ListviewLeftSidebar from './leftSidebar.vue'
import ListviewListData from './listData.vue'

export default {
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BContainer,
    BPagination,
    BOverlay,
    ListviewLeftSidebar,
    ListviewListData,
    VuePullRefresh,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [sizeIconsAction, showMsgRequest],
  computed: {
    ...mapGetters('crm_clients', ['listviewData', 'filterSelected', 'listviewOrderBy', 'totalRows', 'perPage', 'listview_txt_default', 'listviewInit', 'fieldsOrder', 'positionListview']),
    searchTxt: {
      get() {
        return this.$store.getters['crm_clients/searchTxt']
      },
      set(newValue) {
        this.$store.dispatch('crm_clients/setSearchTxt', newValue).catch(error => {
          this.showMsgErrorRequest(error)
        })
      },
    },
    currentPage: {
      get() {
        return this.$store.getters['crm_clients/currentPage']
      },
      set(newValue) {
        this.$store.dispatch('crm_clients/setCurrentPage', newValue).catch(error => {
          this.showMsgErrorRequest(error)
        })
      },
    },
    sizePaginationListviewResponsive() {
      const currentBreakPoint = this.$store.getters['app/currentBreakPoint']
      return ((currentBreakPoint === 'xs') ? 'lg' : 'md')
    },
  },
  watch: {
    $route() {
      this.$refs.leftSidebar.$refs.filterScroll.$el.scrollTop = 0
    },
  },
  beforeDestroy() {
    this.$store.commit('crm_clients/savePositionListview', this.sPostionListview)
  },
  async created() {
    try {
      await this.$store.dispatch('crm_clients/fetchListView', { skipCurrentPage: true }).catch(error => {
        this.showMsgErrorRequest(error)
      })

      await this.$nextTick()

      if ((this.positionListview !== null) && (this.positionListview !== undefined)) {
        const { scrollTop, scrollLeft } = this.positionListview
        this.$refs.listviewScroll.$el.scrollTop = scrollTop
        this.$refs.listviewScroll.$el.scrollLeft = scrollLeft
      }
    } catch (err) {
      //
    }
  },
  methods: {
    listviewFilterOrderBy(field) {
      this.$store.dispatch('crm_clients/listviewFilterOrderBy', field).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    listviewIconOrderBy(filterField, renderClass) {
      let returnIcon = 'ArrowUpIcon'
      let returnClass = 'opacity-0'

      if (this.listviewOrderBy.column === filterField) {
        if (this.listviewOrderBy.sort === 'asc') {
          returnClass = 'opacity-1'
          returnIcon = 'ArrowUpIcon'
        } else {
          returnClass = 'opacity-1'
          returnIcon = 'ArrowDownIcon'
        }
      }

      if (renderClass === true) {
        return returnClass
      }

      return returnIcon
    },
    createNew() {
      this.$router.push('/crm/clients/new')
    },
    listViewRefresh() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('crm_clients/pullToRefreshListview')
          .then(() => {
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },
    async fetchListView() {
      await this.$store.dispatch('crm_clients/fetchListView', { skipCurrentPage: true }).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    listViewSavePositionScroll() {
      this.sPostionListview = {
        scrollTop: this.$refs.listviewScroll.$el.scrollTop,
        scrollLeft: this.$refs.listviewScroll.$el.scrollLeft,
      }
    },
  },
  setup() {
    const CRM_CLIENTS_MODULE_NAME_LIST = 'crm_clients'

    if (!store.hasModule(CRM_CLIENTS_MODULE_NAME_LIST)) {
      store.registerModule(CRM_CLIENTS_MODULE_NAME_LIST, crmClientsListModule)

      onUnmounted(() => {
        if (store.hasModule(CRM_CLIENTS_MODULE_NAME_LIST)) store.unregisterModule(CRM_CLIENTS_MODULE_NAME_LIST)
      })
    }

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    const sPostionListview = {
      scrollTop: 0,
      scrollLeft: 0,
    }

    const { route } = useRouter()
    const routeParamsQuery = computed(() => route.value.query)

    return {
      mqShallShowLeftSidebar,
      sPostionListview,
      routeParamsQuery,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.email-app-list.space-bottom {
  padding-bottom: 2.8rem;

  @include media-breakpoint-down(xs) {
    padding-bottom: 3rem;
  }
}

/* Desativa as animação devido que provoca erro no scroll to refresh */
.capacitor {
  .email-user-list ::v-deep {
    .email-media-list li {
      animation: none !important;
      animation-fill-mode: none !important;
    }
  }
}

.pull-down-container ::v-deep {
  .pull-down-header {
    background-color: inherit !important;
  }

  .pull-down-content {
    font-family: inherit !important;
    color: inherit !important;
    font-size: inherit !important;
  }
}
</style>
